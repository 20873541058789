<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Usage Analysis</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-autocomplete v-model="moduleFilter" :items="modules" label="Modules" item-text="module" item-value="module" outlined hide-details multiple chips small-chips deletable-chips style="max-width: 200px"></v-autocomplete>
    </v-toolbar>
    <v-data-table :items="items" :headers="headers"></v-data-table>
  </v-card>
</template>
<script>
import { ref, onMounted, watch } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const items = ref([])
    const headers = ref([
      { text: 'Page', value: 'page' },
      { text: 'Module', value: 'module' },
      { text: 'Page Loads', value: 'loads' },
      { text: 'User Count', value: 'users' },
      { text: 'Average Time on Page (in seconds)', value: 'avg' }
    ])
    const modules = ref([])
    const startDate = ref('')
    const endDate = ref('')
    const moduleFilter = ref([])

    onMounted(() => {
    //   loadUsers()
      loadModules()
      loadData()
    })

    async function loadModules () {
      const aggregate = [
        { $project: {
          module: { $substr: ['$name', 0, { $indexOfCP: ['$name', '/', 0] }] }
        } },
        { $group: {
          _id: '$module',
          count: { $sum: 1 }
        } },
        { $project: {
          module: '$_id',
          count: 1
        } },
        { $sort: {
          module: 1
        } }
      ]
      const { data } = await root.$feathers.service('system/usage-log').find({ query: { aggregate } })
      modules.value = data
    }

    async function loadData () {
      const aggregate = [
        { $project: {
          userId: 1,
          in: 1,
          out: 1,
          time: { $dateDiff: { startDate: '$in', endDate: '$out', unit: 'second' } },
          page: '$name',
          module: { $substr: ['$name', 0, { $indexOfCP: ['$name', '/', 0] }] }
        } },
        { $group: {
          _id: {
            user: '$userId',
            page: '$page',
            module: '$module'
          },
          count: { $sum: 1 },
          avg: { $avg: '$time' }
        } },
        { $group: {
          _id: {
            page: '$_id.page',
            module: '$_id.module'
          },
          loads: { $sum: '$count' },
          users: { $sum: 1 },
          avg: { $avg: '$avg' }
        } },
        { $project: {
          page: '$_id.page',
          module: '$_id.module',
          loads: 1,
          users: 1,
          avg: 1
        } },
        { $sort: {
          page: 1
        } }
      ]
      const $match = {}
      if ((startDate.value !== '' && startDate.value != null) || (endDate.value !== '' && endDate.value != null)) {
        $match.in = {}
        if (startDate.value !== '' && startDate.value != null) $match.in.$gte = new Date(startDate.value)
        if (endDate.value !== '' && endDate.value != null) $match.in.$lte = new Date(endDate.value)
      }
      if (moduleFilter.value.length > 0) {
        $match['module'] = { $in: moduleFilter.value }
      }
      if (JSON.stringify($match) !== '{}') {
        aggregate.splice(1, 0, { $match })
      }
      const { data } = await root.$feathers.service('system/usage-log').find({ query: { aggregate } })
      items.value = data
    }

    watch(moduleFilter, () => {
      loadData()
    })

    return {
      items,
      headers,
      modules,
      moduleFilter,
      loadData
    }
  }
}
</script>
